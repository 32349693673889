/* Import the Roboto font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1159e7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message-bubble {
  background-color: #ffffff;
  color: black;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.message-bubble::before {
  content: '';
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -10px; /* Adjust this value to move the speech bubble up or down */
  left: 50%;
  transform: translateX(-50%);
}


.welcome {
  opacity: 0;
  animation: fadeIn 2s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fantasy-font {
  font-family: 'Merienda', cursive;
  font-size: 5rem;
  color: #e174f4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.fade-in {
  opacity: 1;
}

/* Home screen */
.home-screen {
  display: flex; /* Use flexbox to arrange items */
  flex-direction: column; /* Arrange items vertically */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  height: 100vh; /* Set height to full viewport height */
  background-color: #ffe6f2; /* Light pink background */
}

.heart-container {
  margin-top: 20px;
}

/* You can style the hearts as you like */
.heart-container svg {
  width: 60px;
  height: 60px;
  fill: rgb(253, 161, 253); /* Pink heart color */
}

/* Dog Screen */

.dogs-screen {
  
  background-color: rgb(253, 237, 253); /* White background */
}

.dogs-screen h1 {
  font-size: 2rem;
}

.dog-image {
  max-height: 600px;
}

/* Add to App.css */
.back-button {
  position: absolute;
  top: 20px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  padding: 10px 20px; /* Adjust as needed */
  background-color: #FF69B4; /* Example color - bright pink for visibility */
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  z-index: 10; /* Ensure it's above other elements */
}


/* Parent container of the back button */
.parent-container {
  position: relative; /* This makes sure that the back button is positioned relative to this container */
  /* rest of your styles... */
}

.dog-images {
  /* your existing styles... */
  margin-top: 0px; /* This ensures there's space for the back button, adjust as needed */
}

.back-button::before,
.back-button::after {
  content: '';
  position: absolute;
  top: 30%; /* Center vertically */
  left: 45%; /* Center horizontally */
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  border-radius: 50%;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for opacity */
}

.back-button::before {
  animation: sparkle 2s infinite; /* Sparkle animations */
}

.back-button:hover {
  color: #340834;
  background-color: #932f6d; /* Lighter purple background on hover */
}

.back-button:hover::before,
.back-button:hover::after {
  opacity: 1; /* Show the sparkles on hover */
}
/* play */
.play-button {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  padding: 10px 20px; /* Adjust as needed */
  background-color: #FF69B4; /* Example color - bright pink for visibility */
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  z-index: 10; /* Ensure it's above other elements */
}
.play-button::before,
.play-button::after {
  content: '';
  position: absolute;
  top: 30%; /* Center vertically */
  left: 45%; /* Center horizontally */
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  border-radius: 50%;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for opacity */
}

.play-button::before {
  animation: sparkle 2s infinite; /* Sparkle animations */
}

.play-button:hover {
  color: #340834;
  background-color: #932f6d; /* Lighter purple background on hover */
}

.play-button:hover::before,
.play-button:hover::after {
  opacity: 1; /* Show the sparkles on hover */
}
@keyframes sparkle {
  0% {
    transform: scale(0); /* Start with no size */
  }
  50% {
    transform: scale(1); /* Grow to full size */
  }
  100% {
    transform: scale(0); /* Shrink back to no size */
  }
}

/* random */
/* BackgroundAnimation.css */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.background {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust based on your layout needs */
  overflow: hidden;
}

.shape, .word {
  position: absolute;
  transition: transform 300ms ease-in-out;
}

.shape {
  width: 50px;
  height: 50px;
  background-color: pink;
  margin: -25px 0 0 -25px;
  transform: rotate(-45deg);
  z-index: 1; /* Lower z-index so it's behind the words */
}

.shape:before,
.shape:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: pink;
  border-radius: 50%;
}

.shape:before {
  top: -25px;
  left: 0;
}

.shape:after {
  top: 0;
  left: 25px;
}

.word {
  font-size: 24px;
  font-weight: bold;
  color: black;
  z-index: 2; /* Higher z-index to be on top of shapes */
}

/* HarryPotter */

/* HarryPotterTheme.css */

.harry-potter-border {
  border: 8px solid #D3C295; /* Parchment color */
  padding: 20px;
  background: #FBF0D9; /* Light parchment background */
  font-family: 'Cormorant', serif; /* A font that might look magical */
  color: #3E2723; /* Color that resembles ink */
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* If you want to add magical icons or images as part of the border, use pseudo-elements */
.harry-potter-border:before,
.harry-potter-border:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.harry-potter-border:before {
  left: -20px; /* Adjust based on the size of your icon */
}

.harry-potter-border:after {
  right: -20px; /* Adjust based on the size of your icon */
}


/*  */

.hp-App {
  text-align: center;
  min-height: 100vh;
}

.hp-header {
  padding: 20px;
  background:  no-repeat center center;
  background-size: cover;
}
/* src/App.css */
/* src/App.css */
.characters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.character-card {
  position: relative;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  color: antiquewhite;
  text-align: center;
  transition: transform 0.2s;
  overflow: hidden; /* Ensure sparkles don't overflow the card */
}

.character-card:hover {
  transform: scale(1.05);
}

.character-card img {
  width: 100%;
  border-radius: 10px;
}

.sparkles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.sparkle {
  position: absolute;
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  opacity: 0;
  animation: sparkle-animation 1s infinite;
}

@keyframes sparkle-animation {
  0%, 100% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* MineSweeper */

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
}

.cell {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  font-size: 20px;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 690px) {
  .cell {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }

}

.cell.revealed {
  background-color: #dddddd;
}

.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 60px;
}

/* Light cells */
.cell.light {
  background-color: #d7d6d9;
}

/* Dark cells */
.cell.dark {
  background-color: #dddbde;
}

/* Style for the reset button */
.reset-button {
  padding: 10px 20px;
  font-size: 1rem;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #da4edf88;
  color: white;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #da4edfd0;
}


/* Align the mines remaining text and reset button */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Center the timer in the middle of the header */
.timer {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: #333;
  margin: 0 20px;
  flex-grow: 1;
  text-align: center;
}

/* Style for the mines-remaining text */
.mines-remaining {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: #333;
  font-weight: 700;
}

/* Style for the reset button */
.reset-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #da4edf88;
  color: white;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #da4edfd0;
}


/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.modal-body {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.modal-close {
  background-color: #e98df7a2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close:hover {
  background-color: #e98df7;
}


/* cellpopup */

.cell-container {
  position: relative;
}

.popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 5px;
}

.popup button {
  display: block;
  margin: 5px 0;
  background-color: #f0f0f0;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #ddd;
}


/* Add to App.css */
.wins {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  padding: 10px 20px; /* Adjust as needed */
}
